<template>
  <v-container class="CategoryCard" fluid v-scrollbar>
    <v-tabs v-model="tab" color="#df6464" centered icons-and-text>
      <v-tab href="#tab-1">
        Category Information
        <v-icon>mdi-folder-information-outline</v-icon>
      </v-tab>
      <v-tab href="#tab-2">
        Inquiry Status
        <v-icon>mdi-book-search-outline</v-icon>
      </v-tab>
      <!-- <v-tab href="#tab-3">
        Users
        <v-icon>mdi-account-supervisor</v-icon>
      </v-tab> -->
      <v-tab href="#tab-4">
        Holidays
        <v-icon>mdi-calendar-search</v-icon>
      </v-tab>
      <v-tab href="#tab-5">
        Template
        <v-icon>mdi-book</v-icon>
      </v-tab>

      <!--------------------------------------
       **        CATEGORY COMPONENT         **
       **  CAN BE SEEN IN COMPONENT FOLDER  **
       -------------------------------------->
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-divider class="mt-3"></v-divider>
          <CategoryInformation />
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-divider class="mt-3"></v-divider>
          <InquiryStatus />
        </v-tab-item>
        <!-- <v-tab-item value="tab-3">
          <v-divider class="mt-3"></v-divider>
          <Users />
        </v-tab-item> -->
        <v-tab-item value="tab-4">
          <v-divider class="mt-3"></v-divider>
          <HolidayCalendar />
        </v-tab-item>
        <v-tab-item value="tab-5">
          <v-divider class="mt-3"></v-divider>
          <Template />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>
<script>
import CategoryInformation from "../components/MastersComponent/CategoryInformation/CategoryInformation.vue";
import HolidayCalendar from "../components/MastersComponent/HolidayCalendar";
// import Users from "../components/MastersComponent/Users";
import Template from "../components/MastersComponent/Template.vue";
import InquiryStatus from "../components/MastersComponent/InquiryStatus/InquiryStatus.vue";

export default {
  data() {
    return {
      tab: null,
    };
  },
  components: {
    CategoryInformation: CategoryInformation,
    HolidayCalendar: HolidayCalendar,
    // Users: Users,
    Template: Template,
    InquiryStatus: InquiryStatus,
  },
};
</script>
<style lang="scss">
.CategoryCard {
  max-height: calc(120vh - 210px);
}
</style>