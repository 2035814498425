<template>
  <v-container>
    <!-- HEADER -->
    <div class="text-center section">
      <h2 class="h2">Inquiry Status Master</h2>
      <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
        Check Inquiry Information Status
      </p>
    </div>
    <!-- FILTERS counter -->
    <v-row class="ma-n6">
      <v-col cols="2" style="margin-top: 25px; margin-left: 15px">
        <v-text-field
          v-model="controlNumber"
          label="Customer Code"
          color="#1976D2"
          outlined
          maxlength="12"
          dense
        ></v-text-field>
      </v-col>
      <v-col>
        <div class="pretty p-switch p-fill ml-n3 mt-3">
          <input type="checkbox" v-model="overdue" />
          <div class="state p-danger">
            <label style="color: #df6464; font-weight: bold">Overdue</label>
          </div>
        </div>
        <div class="pretty p-switch p-fill ml-n3 mt-3">
          <v-radio-group v-model="inquiryFilter" row>
            <v-radio label="All" value="All"></v-radio>
            <v-radio label="Inquiry" value="1"></v-radio>
            <v-radio label="Correction" value="0"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>

    <!-- DATA RECORDS -->
    <v-row>
      <v-col>
        <!-- <h1>{{ startProgress }}/{{ EndProgress }}</h1> -->
        <v-progress-linear
          :style="startProgress == EndProgress ? 'display:none;' : ''"
          :size="200"
          :width="15"
          indeterminate
          color="#DF6464"
        >
        </v-progress-linear>
        <v-data-table
          :headers="headers"
          :style="startProgress == EndProgress ? '' : 'display:none;'"
          :items="FilteredRecord"
          item-key="id"
          height="50vh"
          fixed-header
        >
          <template v-slot:[`item.ReplyDate`]="{ item }">
            <v-row>
              <span :class="item.ReplyDate ? '' : 'noRecord'">
                {{ item.ReplyDate ? item.ReplyDate : "No Record Found!" }}
              </span>
            </v-row>
          </template>
          <template v-slot:[`item.ReplyTime`]="{ item }">
            <v-row>
              <span :class="item.ReplyTime ? '' : 'noRecord'">
                {{ item.ReplyTime ? item.ReplyTime : "No Record Found!" }}
              </span>
            </v-row>
          </template>
          <template v-slot:[`item.SubHandleStaff`]="{ item }">
            <v-row>
              <span :class="item.SubHandleStaff ? '' : 'noRecord'">
                {{ item.SubHandleStaff ? item.SubHandleStaff : "No Record Found!" }}
              </span>
            </v-row>
          </template>
          <template v-slot:[`item.DocumentNumber`]="{ item }">
            <v-row>
              <span :class="item.DocumentNumber ? '' : 'noRecord'">
                {{ item.DocumentNumber ? item.DocumentNumber : "No Record Found!" }}
              </span>
            </v-row>
          </template>
          <template v-slot:[`item.InquiryType`]="{ item }">
            <v-row>
              <span :class="item.InquiryType ? '' : 'noRecord'">
                {{ item.InquiryType ? item.InquiryType : "No Record Found!" }}
              </span>
            </v-row>
          </template>
          <template v-slot:[`item.InquiryItem`]="{ item }">
            <v-row>
              <span :class="item.InquiryItem ? '' : 'noRecord'">
                {{ item.InquiryItem ? item.InquiryItem : "No Record Found!" }}
              </span>
            </v-row>
          </template>
          <template v-slot:[`item.Reply`]="{ item }">
            <v-btn
              @click="OpenThread(item.ConfirmationID)"
              color="#386068"
              small
              outlined
            >
              <v-icon>mdi-forum</v-icon> View
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-overlay v-model="loading">
      <v-progress-circular width="10" size="90" color="#B71C1C" indeterminate
        ><span style="text-shadow: 0 0 10px orange">Loading...</span></v-progress-circular
      >
    </v-overlay>
    <!-- COMPONENTS -->
    <ReplyThread ref="replyThread" />
  </v-container>
</template>

<script>
import moment from "moment";

import keys from "../../../functions/alert";
// import InquiryStatusTable from "../../../functions/httpRequest/ExternalConnection/InquiryStatusTable";
import ReplyThread from "./ReplyTread.vue";
import axios from "axios";
const baseUrl = keys.auth.BaseUrl;
const apikey = keys.auth.apiKey;
export default {
  data() {
    return {
      value123: 0,
      interval: {},
      startProgress: "",
      EndProgress: "",
      overdue: false,
      controlNumber: "",
      inquiryFilter: "All",
      inquiryStatusRecord: [],
      headers: [
        {
          text: "#",
          align: "start",
          value: "id",
        },
        {
          text: "Customer Code",
          value: "CustomerCode",
        },
        {
          text: "Sent Date",
          value: "ReceivedDate",
        },
        {
          text: "Sent Time",
          value: "ReceivedTime",
        },
        {
          text: "Due Date",
          value: "DueDate",
        },
        {
          text: "Reply Date",
          value: "ReplyDate",
        },
        {
          text: "Reply Time",
          value: "ReplyTime",
        },
        {
          text: "Status",
          value: "Status",
        },
        {
          text: "Sender Status",
          value: "SenderStatus",
        },
        {
          text: "Sender Name",
          value: "SenderName",
        },
        {
          text: "Department",
          value: "Department",
        },
        {
          text: "HandleStaff",
          value: "HandleStaff",
        },
        {
          text: "SubHandleStaff",
          value: "SubHandleStaff",
        },
        {
          text: "Inquiry Type",
          value: "InquiryType",
        },
        {
          text: "Inquiry Item",
          value: "InquiryItem",
        },

        {
          text: "Document Number",
          value: "DocumentNumber",
        },
        {
          text: "Reply",
          value: "Reply",
        },
      ],
      loading: false,
    };
  },
  mounted() {
    // this.InquiryStatusRecord();
    // this.loading = true;
    this.InquiryCount();
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  components: {
    ReplyThread: ReplyThread,
  },
  computed: {
    FilteredRecord() {
      let record = this.inquiryStatusRecord;

      if (this.overdue)
        record = this.inquiryStatusRecord.filter((el) => el.overdue && !el.ReplyDate);

      if (this.controlNumber)
        record = record.filter((el) =>
          el.CustomerCode.toUpperCase().includes(this.controlNumber.toUpperCase())
        );
      //If else statement

      if (this.inquiryFilter == "0") {
        //Correction
        record = record.filter((el) => el.Type == "0");
      }

      if (this.inquiryFilter == "1") {
        //Inquiry
        record = record.filter((el) => el.Type == "1");
      }

      return record;
      // else{
      //   console.log("ALL") //BOTH await
      // }
    },
  },
  methods: {
    async InquiryCount() {
      const { data, status } = await axios.get(
        `${baseUrl}/getInquiryStatusCount`,
        apikey
      );

      if (status == 200) {
        // console.log(data[0]);
        let countData = data[0].InquiryCount;
        let MathCount = Math.floor(countData / 3000);
        this.EndProgress = MathCount;
        for (let i = 0; i <= MathCount; i++) {
          this.startProgress = i;
          await this.InquiryStatusRecord(i * 3000);
        }
      }
    },

    OpenThread(id) {
      this.$refs.replyThread.OpenDialog(id);
    },
    //*************************************//
    //  GETTING ALL INQUIRY STATUS RECORD  //
    //*************************************//

    InquiryStatusRecord(offset) {
      return new Promise((resolve) => {
        axios.get(`${baseUrl}/getInquiryStatusOffset/${offset}`).then((res) => {
          let filterInquiry = res.data.filter((rec) => {
            rec.DueDate = moment(rec.DueDate).format("YYYY-MM-DD");
            rec.ReceivedDate = moment(rec.ReceivedDate).format("YYYY-MM-DD");
            rec.ReplyDate = moment(rec.ReplyDate).format("YYYY-MM-DD");
            return rec;
          });
          console.log(filterInquiry);
          this.inquiryStatusRecord = [...this.inquiryStatusRecord, ...filterInquiry];
        });
        resolve("OK " + offset);
      });
    },

    //*************************************//
    //       FOR REFORMATING DATE          //
    //*************************************//

    Date: (val) => moment(val).format("YYYY-MM-DD"),
  },
};
</script>
<style lang="scss">
.noRecord {
  color: red;
  font-weight: bold;
}
</style>
