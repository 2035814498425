<template>
  <!-- FOR INQUIRY -->
  <div>
    <v-btn class="mr-2" color="#df6464" @click="addRecords" dense small outlined
      >Add Inquiry</v-btn
    >
    <v-dialog v-model="openModal" width="800">
      <v-card style="margin: 0 auto; padding: 30px" width="800">
        <v-card-title primary-title> Sender Information</v-card-title>
        <v-divider></v-divider>
        <v-container v-scrollbar style="height: 555px">
          <v-row class="mb-n10">
            <!-- CATEGORY -->
            <v-col>
              <span style="color: grey; font-size: 15px"> Category </span>
              <v-select
                color="#df6464"
                v-model="insert.Category"
                :items="categories"
                item-text="text"
                item-value="value"
                outlined
                dense
              >
              </v-select
            ></v-col>
            <!-- INQUIRY ITEM -->
            <v-col>
              <span style="color: grey; font-size: 15px"> Inquiry Item </span>
              <v-text-field
                color="#df6464"
                v-model="insert.InquiryItem"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-n10">
            <!-- EMPLOYEE ID (ICHIJO) -->

            <v-col>
              <span style="color: grey; font-size: 15px"> Employee ID</span>
              <v-text-field
                color="#df6464"
                v-model="insert.IchijoIncharge_ID"
                @keyup="GetUser('IchijoIncharge', 'IchijoIncharge_ID')"
                outlined
                dense
                clearable
              ></v-text-field>
            </v-col>
            <!-- EMPLOYEENAME (ICHIJO) -->
            <v-col>
              <span style="color: grey; font-size: 15px"> EmployeeName</span>
              <v-text-field
                color="#df6464"
                v-model="IchijoIncharge"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn class="mt-6" block color="success" @click="viewListofSender()"
                ><v-icon>mdi-account-eye</v-icon> List of Sender</v-btn
              >
            </v-col>
            <v-dialog v-model="SenderDialog" width="900">
              <v-card style="margin: 0 auto; padding: 30px">
                <v-card-title primary-title> List of Sender</v-card-title>
                <v-divider></v-divider>
                <v-container v-scrollbar style="height: 455px">
                  <v-text-field
                    label="Search"
                    dense
                    outlined
                    v-model="searchLisOfSender"
                  />
                  <v-data-table
                    :search="searchLisOfSender"
                    :headers="headers"
                    :items="listOfSender"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn @click="addSender(item)" color="success">Add </v-btn>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card>
            </v-dialog>
            <!-- TODO Dialog -->
            <v-dialog v-model="CCEmployeeDialog" width="900">
              <v-card style="margin: 0 auto; padding: 30px">
                <v-card-title primary-title> List of CC</v-card-title>
                <v-divider></v-divider>
                <v-container v-scrollbar style="height: 455px">
                  <v-text-field
                    label="Search"
                    dense
                    outlined
                    v-model="searchLisOfSender"
                  />
                  <v-data-table
                    :search="searchLisOfSender"
                    :headers="headers"
                    :items="listOfSender"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn @click="addCC(item)" color="success">Add </v-btn>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card>
            </v-dialog>
            <!-- TODO END Dialog -->
            <!-- <v-dialog v-model="SenderDialog" width="900">
              <v-card style="margin: 0 auto; padding: 30px">
                <v-card-title primary-title> List of Sender</v-card-title>
                <v-divider></v-divider>
                <v-container v-scrollbar style="height: 455px">
                  <v-text-field
                    label="Search"
                    dense
                    outlined
                    v-model="searchLisOfSender"
                  />
                  <v-data-table
                    :search="searchLisOfSender"
                    :headers="headers"
                    :items="listOfSender"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn @click="addSender(item)" color="success"
                        >Add
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card>
            </v-dialog> -->
          </v-row>
          <v-row class="mb-n10">
            <!-- EMAIL ICHIJO -->
            <v-col>
              <span style="color: grey; font-size: 15px"> Email</span>
              <v-text-field
                color="#df6464"
                v-model="insert.EmailIchijo"
                :rules="emailRules"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-n10">
            <!-- LIST OF DEPARTMENT -->
            <v-col>
              <!-- {{JSON.parse(insert.Department)}} -->
              <span style="color: grey; font-size: 15px"> Department </span>
              <v-autocomplete
                small-chips
                multiple
                color="#df6464"
                chips
                :items="ListDepartment"
                v-model="insert.Department"
                @keyup.enter="insertData"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>

          <!----------------------- 
           *     CC INFORMATION   *
           ----------------------->
          <v-card-title primary-title> CC Information </v-card-title>
          <v-card-subtitle style="color: red"> note:Cc can be multiple</v-card-subtitle>

          <v-divider></v-divider>
          <v-row
            ><v-col>
              <!-- TODO BTN -->
              <v-btn
                style="float: right"
                dense
                color="success"
                @click="CCEmployeeDialog = true"
                >ADD Employee</v-btn
              >
              <!-- TODO End BTN -->
              <!-- <v-btn
                v-if="insert.CcIchijoIncharge || insert.CcIchijo"
                @click="$refs.InformationComponentRef.updateDialog()"
                color="success"
                style="float: right"
                dense
                >View List of CC</v-btn
              > -->
            </v-col></v-row
          >

          <!-- <v-row class="mb-n10">
            EMPLOYEE ID (ICHIJO)
            <v-col>
              <span style="color: grey; font-size: 15px"> Employee ID </span>
              <v-text-field
                color="#df6464"
                v-model="ccEmployeeIdIchijo"
                @keyup="GetCCUser('ccEmployeeNameIchijo', 'ccEmployeeIdIchijo')"
                outlined
                dense
              ></v-text-field>
            </v-col>
            EMPLOYEENAME (ICHIJO)
            <v-col>
              <span style="color: grey; font-size: 15px"> EmployeeName </span>
              <v-text-field
                color="#df6464"
                v-model="ccEmployeeNameIchijo"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col>
              <v-btn
                @click="
                  AddCcEmployee(
                    'CcIchijoIncharge_ID',
                    'CcIchijoIncharge',
                    'ccEmployeeNameIchijo',
                    'ccEmployeeIdIchijo'
                  )
                "
                color="primary"
                block
                dense
                >Add Employee</v-btn
              >
            </v-col>
          </v-row> -->
          <!-- <v-row class="mb-n10">
            CC EMAIL ICHIJO
            <v-col>
              <span style="color: grey; font-size: 15px"> CC Email </span>
              <v-text-field
                v-model="CcIchijo"
                color="#df6464"
                outlined
                dense
              ></v-text-field>
              <div
                v-if="insert.CcIchijoIncharge || insert.CcIchijo"
                class="mb-5 mt-n5"
                @click="$refs.InformationComponentRef.updateDialog()"
              >
                <span style="cursor: pointer">
                  <v-icon color="#042069">mdi-chat-alert</v-icon> Check Inserted
                  Information.
                </span>
              </div>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col>
              <v-btn @click="AddCc('CcIchijo')" color="primary" block dense
                >ADD CC EMAIL</v-btn
              >
            </v-col>
          </v-row> -->
          <!-- TODO Table -->
          <!-- <span class="text--secondary ml-4"> Employee Number </span>
          <v-row>
            <v-col>
              <v-autocomplete
                class="shrink ml-4"
                v-model="CcCode"
                :items="listOfSender"
                item-text="Name"
                :item-value="listOfSender"
                small-chips
                multiple
                color="#df6464"
                dense
                outlined
                style="width: 50%"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-btn color="success" dense @click="AddCC()"> Add </v-btn>
            </v-col>
          </v-row> -->
          <v-simple-table v-if="this.insert.CcIchijoIncharge">
            <thead>
              <tr>
                <th>EmployeeName</th>
                <th>EmailAddress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in insert.CcIchijoIncharge" :key="i">
                <td>{{ item }}</td>
                <td>{{ insert.CcIchijo[i] }}</td>
                <td>
                  <v-btn
                    class="ma-2 button"
                    @click="onDelete(item, insert.CcIchijo[i])"
                    color="red accent-3"
                    dense
                    small
                    outlined
                    ><v-icon color="red accent-3">mdi-trash-can-outline</v-icon>
                    Delete</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-simple-table v-else>
            <thead>
              <tr>
                <th>EmployeeName</th>
                <th>EmailAddress</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in ccSelect" :key="i">
                <td>{{ item.Name }}</td>
                <td>{{ item.MailAddress }}</td>
                <!-- <td>{{ insert.CcIchijo[i] }}</td> -->
                <!-- <td>
                  <v-btn
                    class="ma-2 button"
                    @click="onDelete(item, insert.CcIchijo[i])"
                    color="red accent-3"
                    dense
                    small
                    outlined
                    ><v-icon color="red accent-3">mdi-trash-can-outline</v-icon>
                    Delete</v-btn
                  >
                </td> -->
              </tr>
            </tbody>
          </v-simple-table>
          <!-- TODO  End-->
          <v-row class="mb-n5">
            <!-- DATE CREATED -->
            <v-col>
              <span style="color: grey; font-size: 15px"> Date Created </span>
              <v-text-field
                color="#df6464"
                v-model="insert.DateCreated"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="mt-n5 mb-n5">
          <v-row>
            <v-col>
              <!-- ACTION -->
              <v-btn @click="insertData" color="#26a69a" style="color: white" block>
                {{ buttonValue }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- FOR CC INFORMATION -->
    <InsertedInformationComponent
      :records="insert"
      @deleteRecord="DeleteCc"
      ref="InformationComponentRef"
    />
    <v-overlay v-model="loading" color="white" opacity="1">
      <span style="color: #df6464">Getting Data Please wait... </span>
      <v-progress-linear indeterminate color="#DF6464" height="10"></v-progress-linear>
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment";
import allApi from "../../../functions/httpRequest/hrdappsAPI/allConnection";
import objects from "../../../functions/alert";
import keys from "../../../functions/alert";
import InquiryCategory from "../../../functions/httpRequest/ExternalConnection/InquiryCategory";
import InsertedInformationComponent from "../CategoryInformation/InsertedInformation.vue";
import CorrectionTable from "../../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import UpdateCategory from "../../../functions/httpRequest/ExternalConnection/CategoryInformationTable";
import axios from "axios";
export default {
  data() {
    return {
      searchLisOfSender: "",
      SenderDialog: false,
      openModal: false,
      ccEmployeeNameIchijo: "",
      ccEmployeeIdIchijo: "",
      ccEmployeeNameGc: "",
      ccEmployeeIdGc: "",
      buttonValue: "",
      CcIchijo: "",
      CcGc: "",
      IchijoIncharge: "",
      GcIncharge: "",
      updateID: "",
      headers: [
        {
          text: "UserId",
          align: "center",
          sortable: false,
          value: "UserId",
        },
        {
          text: "Name",
          sortable: false,

          align: "center",
          value: "Name",
        },

        {
          text: "MailAddress",
          sortable: false,

          align: "center",
          value: "MailAddress",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      arry1: [],
      arry2: [],
      listOfSender: [],
      insert: {},
      categories: [],
      ListDepartment: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      CCHeaders: [
        "CcIchijo",
        "CcIchijoIncharge",
        "CcIchijoIncharge_ID",
        "CcGc",
        "CcGcIncharge",
        "CcGcIncharge_ID",
      ],
      ReceiverHeaders: [
        "EmailGc",
        "EmailIchijo",
        "IchijoIncharge",
        "IchijoIncharge_ID",
        "GcIncharge",
        "GcIncharge_ID",
      ],
      multipleDepartment: ["Department"],
      // CcCode: [],
      ccSelect: [],
      CCEmployeeDialog: false,
      loading: false,
    };
  },
  components: {
    InsertedInformationComponent: InsertedInformationComponent,
  },
  async created() {
    this.loading = true;
    // CALL FUNCTION ON CREATED
    await this.getlistofSenderHRD();
    await this.getListofSender();
    this.getCategories();
    this.getDepartment();
    // ADD DATE/TIME
    setInterval(() => {
      this.$set(this.insert, "DateCreated", moment().format("YYYY-MM-DD HH:mm:ss"));
    }, 1000);
  },

  watch: {
    // WATCH FOR MODAL
    openModal(newValue) {
      if (!newValue) {
        this.IchijoIncharge = "";
        this.GcIncharge = "";
        this.$emit("updateInformation", "");
      }
    },
  },
  methods: {
    addSender(val) {
      this.SenderDialog = false;
      this.insert.IchijoIncharge_ID = Object.assign(val.UserId);
      this.IchijoIncharge = val.Name;
    },
    onDelete(item, Cc) {
      // console.log(item);
      // this.insert.CcIchijoIncharge.splice(item, 1)
      // this.insert.CcIchijo.splice(item, 0);
      let index = this.insert.CcIchijoIncharge.findIndex((r) => r == item);
      this.insert.CcIchijoIncharge.splice(index, 1);
      this.OnDeleteCC(Cc);
    },
    OnDeleteCC(i) {
      // console.log(i);
      let index1 = this.insert.CcIchijo.findIndex((r) => r == i);
      this.insert.CcIchijo.splice(index1, 1);
    },
    addCC(item) {
      if (!this.insert) {
        this.insert = {}; // Create insert object if it doesn't exist
      }

      if (!this.insert.CcIchijoIncharge) {
        this.insert.CcIchijoIncharge = []; // Create CcIchijoIncharge array if it doesn't exist
      }

      if (!this.insert.CcIchijo) {
        this.insert.CcIchijo = []; // Create CcIchijo array if it doesn't exist
      }

      this.insert.CcIchijoIncharge.push(item.Name);
      this.insert.CcIchijo.push(item.MailAddress);
      this.CCEmployeeDialog = false;
    },
    async getlistofSenderHRD() {
      await CorrectionTable.Get().then((res) => {
        let getUser = res.data.map((rec) => {
          rec = {
            UserId: rec.EmployeeCode,
            Name: rec.EmployeeName,
            MailAddress: rec.Email ? rec.Email : "No Email address yet",
          };
          return rec;
        });
        this.arry1 = getUser;
        // console.log(this.arry1, " array1");

        // this.listOfSender = getUser;
        // console.log(getUser, " dkhwkaw");
      });
    },
    async getListofSender() {
      // console.log(getUser, "getUSer");
      const BaseUrlUSERS =
        "https://v7ck8ajvp1.execute-api.us-east-2.amazonaws.com/prod/getAllUsers";
      const apiKeyUSERS = {
        headers: { "x-api-key": "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3" },
      };
      await axios.get(`${BaseUrlUSERS}`, apiKeyUSERS).then((res) => {
        let getSender = res.data.map((rec) => {
          rec = {
            UserId: rec.UserId,
            Name: rec.Name,
            MailAddress: rec.MailAddress ? rec.MailAddress : "No Email address yet",
          };
          return rec;
        });
        this.arry2 = getSender;
        // console.log(this.arry2, " array2");
        this.listOfSender = this.arry1.concat(this.arry2);
        this.loading = false;
        // console.log(this.listOfSender);
        // this.listOfSender.filter((res) => {
        //   getSender.filter((rec) => {
        //     res.push(rec);
        //   });
        // });

        // this.listOfSender.push(getSender);
        // console.log(getSender, "GET JA");
        // let EmployeeName = `${res.data.FirstName}` + `${res.data.LastName}`;
      });
    },

    viewListofSender() {
      this.SenderDialog = true;
    },
    DeleteCc() {
      // console.log(this.insert);
    },
    AddCc(type) {
      if (!this.CcIchijo) {
        return this.$toast.error("Please Input CC", objects.alert);
      }
      this.insert[type] = this.insert[type] || [];
      this.$set(this.insert[type], this.insert[type].length, this[type]);
      this[type] = "";
      this.$toast.success("Added Successfully", objects.alert);
    },
    //*********************************//
    // FOR EDIT FUNCTION IN CATEGORY   //
    //*********************************//

    insertData() {
      // if(!this.ccEmployeeNameIchijo){
      //   return this.$toast.error("Please Input Employee ID", objects.alert);

      // }
      // if(!this.CcIchijo){
      //   return this.$toast.error("Please Input Employee ID", objects.alert);

      // }
      // if(!this.ReceiverHeaders){
      //   return this.$toast.error("Please Input Employee ID and CC Email", objects.alert);
      // }
      // if(!this.CCHeaders){
      //   return this.$toast.error("Please Input Employee ID and CC Email", objects.alert);
      // }
      //Click
      // FOR UPDATE RECORDS
      if (this.buttonValue == "Update") {
        // CHECK FOR SOME DATA IF NO VALUE
        this.insert.IchijoIncharge = this.IchijoIncharge;
        this.ReceiverHeaders.forEach((Cc) => {
          if (this.insert[Cc] == "") this.insert[Cc] = null;
        });

        this.CCHeaders.forEach((Cc) => {
          if (!this.insert[Cc]) this.insert[Cc] = null;
        });

        // }
        this.insert.id = this.updateID;
        // VALIDATE AND GET ALL DATA TO BE UPDATED
        const Category = this.categories.filter((res) => {
          if (res.value == this.insert.Category) {
            return res.text;
          }
        });
        for (let x in this.InquiryMasterRecord) {
          if (this.InquiryMasterRecord[x].id == this.updateID) {
            this.InquiryMasterRecord[x].InquiryItem = this.insert.InquiryItem;
            this.InquiryMasterRecord[x].InquireCategory = Category[0].text;
            this.InquiryMasterRecord[x].Department = this.insert.Department;
          }
        }
        this.openModal = false;

        // CHECK FOR CC
        this.CCHeaders.forEach((Cc) => {
          if (this.insert[Cc]) this.insert[Cc] = JSON.stringify(this.insert[Cc]);
        });
        this.multipleDepartment.forEach((Department) => {
          if (this.insert[Department])
            this.insert[Department] = JSON.stringify(this.insert[Department]);
        });

        // UPDATE RECORD IN DATABASE
        UpdateCategory.updateCategory(this.insert).then(() => {
          this.$toast.success("Update Successfully", objects.alert);
          this.$emit("updateInformation", "");
        });
      } else {
        // FOR NEW RECORD
        if (!this.insert.Category) {
          this.$toast.error("Please Add Category", objects.alert);
          return false;
        } else if (!this.insert.InquiryItem) {
          this.$toast.error("Please Add Inquiry Item", objects.alert);
          return false;
        } else if (!this.insert.Department) {
          this.$toast.error("Please Add Department", objects.alert);
          return false;
        } else {
          this.insert.IchijoIncharge = this.IchijoIncharge;
          this.openModal = false;

          // CHECK FOR CC
          this.CCHeaders.forEach((Cc) => {
            if (this.insert[Cc]) this.insert[Cc] = JSON.stringify(this.insert[Cc]);
          });

          this.multipleDepartment.forEach((Department) => {
            if (this.insert[Department])
              this.insert[Department] = JSON.stringify(this.insert[Department]);
          });
          // SAVE TO DATABASE
          this.$axios
            .post(
              `${this.$BaseUrl}/insertInquiryMaster`,
              { data: this.insert },
              this.$BaseApiKey
            )
            .then(() => {
              this.$emit("updateInformation", "");
              this.$toast.success("Insert Successfully", objects.alert);
            });
          this.IchijoIncharge = "";
          this.GcIncharge = "";
          this.insert = {};
          this.insert.DateCreated = moment().format("YYYY-MM-DD HH:mm:ss");
        }
      }
    },
    //*********************************//
    //     FOR NEW RECORD MODAL        //
    //*********************************//

    addRecords() {
      this.insert = {};
      this.buttonValue = "Insert";
      this.openModal = true;
    },
    //**********************************************************//
    //     AFTER CLICKING UPDATE BUTTON IN PARENT COMPONENT     //
    //**********************************************************//

    onUpdate(index) {
      this.insert = {};
      const Category = this.categories.filter((res) => res.text == index.Category);
      // this.insert.Department =
      this.insert = { ...index };
      this.insert.Category = Category[0].value;
      this.insert.Department = JSON.parse(this.insert.Department);
      // console.log(this.insert.Department.split(","))
      // CHECK FOR CC INFORMATION
      // CONVERT ARRAY TO TEXT
      this.CCHeaders.forEach((Cc) => {
        if (index[Cc]) this.insert[Cc] = this.insert[Cc].split(", ");
      });
      //  this.multipleDepartment.forEach((Department) => {
      //   let newArrDept =[];
      //   newArrDept.push(this.insert[Department])
      //   console.log(index.Department)
      //   // if(index[Department].Department.length > 1){
      //   //   // this.insert[Department];
      //   //           console.log(JSON.stringify(newArrDept))

      //   // }
      //   if (index[Department]) this.insert[Department] = newArrDept;
      // });
      this.IchijoIncharge = index.IchijoIncharge;
      this.GcIncharge = index.GcIncharge;
      this.updateID = index.id;
      this.buttonValue = "Update";
      this.openModal = true;
    },
    //*************************************//
    //   GET CATEGORY AND ARRANGE OBJECT   //
    //*************************************//

    getCategories() {
      InquiryCategory.GetSelectCategories().then(
        (res) =>
          (this.categories = res.data.map((el) => {
            return {
              text: el.Japanese,
              value: el.id,
              inquiryID: el.InquiryID,
            };
          }))
      );
    },
    //**********************************************//
    //  GET ALL DEPARTMENT IN HRD PLAN PRODUCTION   //
    //**********************************************//

    getDepartment() {
      axios.get(`${keys.auth.BaseUrl}/getdepartment`, keys.auth.apiKey).then((res) => {
        let record = res.data;
        // REMOVE DUPLICATE DEPARTMENT
        this.ListDepartment = [...new Set(record.map((data) => data.Department))].sort();
      });
    },
    //*************************************//
    //       GET EMPLOYEE ID AND NAME      //
    //       !ONKEYUP                      //
    //*************************************//

    GetUser(Incharge, EmployeeId) {
      // GET AFTER USER TYPE 5 DIGIT ID NUMBER
      if (this.insert[EmployeeId].length >= 4) {
        if (this.insert[EmployeeId] == "0242") {
          this.insert[Incharge] = "SHIGERU TOMINAGA";
          this[Incharge] = "SHIGERU TOMINAGA";
          return;
        } else if (this.insert[EmployeeId] == "0417") {
          // this.insert[Incharge] = "SADAMITSU FUKAYA";
          // this[Incharge] = "SADAMITSU FUKAYA";
          this.insert[Incharge] = "深谷定充";
          this[Incharge] = "深谷定充";
          return;
        }
        const BaseUrlUSERS =
          "https://v7ck8ajvp1.execute-api.us-east-2.amazonaws.com/prod/getAllUsers";
        const apiKeyUSERS = {
          headers: { "x-api-key": "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3" },
        };
        axios.get(`${BaseUrlUSERS}`, apiKeyUSERS).then((res) => {
          // console.log(res.data);
          let getName = res.data.filter((rec) => {
            if (this.insert[EmployeeId] == rec.UserId) {
              return rec;
            }
          });
          // console.log(getName);
          // let EmployeeName = `${res.data.FirstName}` + `${res.data.LastName}`;
          this.insert[Incharge] = getName[0].Name;
          this[Incharge] = getName[0].Name;
        });

        allApi
          .HRDSpecificMasterlist(this.insert[EmployeeId])
          .then((res) => {
            this.insert[Incharge] = res.EmployeeName;
            this[Incharge] = res.EmployeeName;
          })
          .catch(() => console.clear());
      } else {
        // REMOVE RECORD FOR < 5 LENGTH
        this.insert[Incharge] = "";
        this[Incharge] = "";
      }
    },
    //*******************************************************//
    //        GET EMPLOYEE ID AND NAME FOR  CC               //
    //        !ONKEYUP                                       //
    //*******************************************************//

    GetCCUser(Incharge, EmployeeId) {
      // GET AFTER USER TYPE 5 DIGIT ID NUMBER

      if (this[EmployeeId] >= 4) {
        if (this[EmployeeId] == "0242") {
          this[Incharge] = "SHIGERU TOMINAGA";
          return;
        } else if (this[EmployeeId] == "0417") {
          this[Incharge] = "SADAMITSU FUKAYA";
          return;
        }

        allApi
          .HRDSpecificMasterlist(this[EmployeeId])
          .then((res) => (this[Incharge] = res.EmployeeName))
          .catch(() => console.log({ Message: "No record Found" }));
      } else {
        this[Incharge] = "";
      }
    },

    //*******************************//
    //      ADD CC INFORMATION       //
    //*******************************//

    AddCcEmployee(id, incharge, employeeField, idField) {
      if (!this.ccEmployeeNameIchijo) {
        return this.$toast.error("Please Input Employee ID", objects.alert);
      }
      if (!this[employeeField])
        return this.$toast.error("Employee not found", objects.alert);
      this.insert[id] = this.insert[id] || [];
      this.insert[incharge] = this.insert[incharge] || [];
      this.$set(this.insert[incharge], this.insert[incharge].length, this[employeeField]);
      this.$set(this.insert[id], this.insert[id].length, this[idField]);
      this[employeeField] = "";
      this[idField] = "";
      this.$toast.success("Added Successfully", objects.alert);
    },
  },
};
</script>
<style lang="scss">
.info {
  color: #042069;
  cursor: pointer;
}
.button {
  transform: scale(80%);
  margin-left: 90px;
}
.btn {
  transform: scale(80%);
}
</style>
