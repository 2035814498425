<template>
  <v-container fluid v-scrollbar class="categoryCard">
    <!-- HEADER -->
    <div class="text-center section">
      <h2 class="h2">Inquiry Confirmation Master</h2>
      <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
        Category information
      </p>
    </div>
    <v-row>
      <v-col class="d-flex justify-start">
        <!-- INQUIRY BUTTON -->
        <Inquiry ref="addCategoryRef" @updateInformation="getInquiryMaster" />
        <!-- CATEGORY BUTTON -->
        <Category />
        <v-btn class="ml-2" small outlined color="#DF6464" @click="MasterExtract()"
          >Extract</v-btn
        >
        <!-- FILTER RADIOBUTTON  -->
        <div class="pretty p-switch p-fill ml-2 mt-2">
          <input type="checkbox" v-model="toggleFilter" />
          <div class="state p-danger">
            <label style="color: #df6464; font-weight: bold">Filter</label>
          </div>
        </div>
      </v-col>
    </v-row>
    <transition name="fade">
      <v-row class="ma-n6 mb-n13" v-show="toggleFilter">
        <v-col>
          <Filters
            @GetFilter="getFilterRecord"
            :AllRecord="InquiryMasterRecord"
            :toggle="toggleFilter"
          />
        </v-col>
      </v-row>
    </transition>
    <v-row>
      <v-col>
        <v-data-table
          height="48vh"
          fixed-header
          :headers="headers"
          :items="allRecords"
          :item-per-page="10"
          class="elevation-2"
        >
          <template v-slot:[`item.EmailIchijo`]="{ item }">
            <v-row>
              <span v-if="item.EmailIchijo">{{ item.EmailIchijo }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>
          <template v-slot:[`item.IchijoIncharge`]="{ item }">
            <v-row>
              <span v-if="item.IchijoIncharge">{{ item.IchijoIncharge }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>

          <template v-slot:[`item.CcIchijoIncharge`]="{ item }">
            <v-row>
              <span v-if="item.CcIchijoIncharge">{{ item.CcIchijoIncharge }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>

          <template v-slot:[`item.CcIchijo`]="{ item }">
            <v-row>
              <span v-if="item.CcIchijo">{{ item.CcIchijo }}</span>
              <span v-else style="color: red; font-weight: bold">No Record Found!</span>
            </v-row>
          </template>

          <template v-slot:[`item.Action`]="{ item }">
            <td v-if="!item.DeletedDate">
              <v-btn
                @click="hideInquiry(item)"
                class="mr-3 ma-2 button"
                color="orange"
                dense
                small
                outlined
                ><v-icon color="orange">mdi-eye-off</v-icon>Hide Inquiry</v-btn
              >
            </td>
            <td v-else>
              <v-btn
                @click="showInquiry(item)"
                class="mr-3 ma-2 button"
                color="blue accent-3"
                dense
                small
                outlined
                ><v-icon color="blue accent-3">mdi-eye</v-icon>Show Inquiry</v-btn
              >
            </td>
            <td>
              <v-btn
                class="mr-3 ma-2 button"
                @click="$refs.addCategoryRef.onUpdate(item)"
                color="success"
                dense
                small
                outlined
                ><v-icon color="success">mdi-pencil</v-icon> Edit</v-btn
              >
            </td>
            <!-- <v-btn
                outlined
                dense
                small
                color="blue accent-3"
                class="ma-2 button"
                @click="hidden = !hidden"
                ><v-icon>{{ hidden ? "mdi-eye-off" : "mdi-eye" }}</v-icon
                >{{ hidden ? "Hide" : "Show" }}</v-btn
              > -->
            <td>
              <v-btn
                class="ma-2 button"
                @click="onDelete(item)"
                color="red accent-3"
                dense
                small
                outlined
                ><v-icon color="red accent-3">mdi-trash-can-outline</v-icon> Delete</v-btn
              >
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <v-overlay v-model="loading">
      <v-progress-circular width="10" size="90" color="#B71C1C" indeterminate
        ><span style="text-shadow: 0 0 10px orange"
          >Loading...</span
        ></v-progress-circular
      >
    </v-overlay> -->
  </v-container>
</template>

<script>
import moment from "moment";
import Inquiry from "./AddEditInquiry.vue";
import Category from "./AddEditCategory.vue";
import Filter from "./Filter.vue";
import objects from "../../../functions/alert";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import _ from "lodash";
export default {
  data() {
    return {
      hidden: false,
      showInsertModal: true,
      additionalEdit: false,
      toggleFilter: false,
      hoverEdit: "",
      InquiryItem: "",
      id: "",
      Category: "",
      Name: "",
      btnHover: "",
      updateID: "",
      Department: "R&D",
      InquiryMasterRecord: [],
      personIncharge: [],
      categories: [],
      inchargeID: [],
      categoryData: {},
      filter: {},
      headers: [
        { text: "Inquiry Category", value: "Category" },
        { text: "Inquiry Item", value: "InquiryItem" },
        { text: "Department", value: "Department" },
        { text: "Handle By", value: "IchijoIncharge" },
        { text: "Email", value: "EmailIchijo" },
        { text: "Cc Handle By", value: "CcIchijoIncharge" },
        { text: "Cc Email", value: "CcIchijo" },

        { text: "Date Created", value: "DateCreated" },
        { text: "Action", value: "Action" },
      ],
      CCHeaders: ["CcIchijo", "CcIchijoIncharge", "CcIchijoIncharge_ID"],
      // loading: false,
    };
  },
  // COMPONENT FOR INQUIRY AND CATEGORY
  components: {
    Inquiry: Inquiry,
    Category: Category,
    Filters: Filter,
  },
  async created() {
    // this.loading = true;
    // CALL INQUIRYMASTER FUNCTION
    await this.getInquiryMaster();
    // console.log(this.toggleFilter);
  },
  computed: {
    //****************************//
    //    COMPUTE ALL RECORDS     //
    //    W/ WO/ FILTER           //
    //****************************//

    allRecords() {
      let filterRecord = this.InquiryMasterRecord; //All Records

      // FUNCTION FOR FILTERING RECORD
      const Filter = (val) => {
        filterRecord = filterRecord.filter((el) => el[val] == this.filter[val]);
      };

      // LOOP HOW MANY FILTER ROWS WILL FILTER
      for (let x in this.filter) if (this.filter[x]) Filter(x);

      return filterRecord;
    },
  },
  methods: {
    MasterExtract() {
      const firstProcess = () => {
        return new Promise((resolve) => {
          const headers = {
            CategoryID: "Sort No. For Inquiry Category",
            Category: "Inquiry Category",
            IchijoIncharge_ID: "Sort No.For Inquiry Item",
            InquiryItem: "Inquiry Item",
            Department: "Department",
            IchijoIncharge: "Handle By",
            EmailIchijo: "Email",
            CcIchijoIncharge: "CC Handle By",
            CcIchijo: "CC Email",
            DeletedDate: "Show and Hide Category",
            DateCreated: "Date Created",
          };
          const excel_data = [...this.allRecords];
          const workbook = new Excel.Workbook();
          const DetailedSheet = workbook.addWorksheet("Detailed");
          // let indexCell = { detailed: 1, summary: 1 };
          // console.log(excel_data);
          DetailedSheet.columns = [
            { key: "A1", width: 25 },
            { key: "B1", width: 25 },
            { key: "C1", width: 25 },
            { key: "D1", width: 60 },
            { key: "E1", width: 60 },
            { key: "F1", width: 40 },
            { key: "G1", width: 40 },
            { key: "H1", width: 60 },
            { key: "I1", width: 60 },
            { key: "J1", width: 60 },
            { key: "K1", width: 25 },
          ];

          let sortData = _.orderBy(
            excel_data,
            [
              function (o) {
                return o.CategoryID;
              },
            ],
            "asc"
          );
          sortData.unshift(headers);
          // console.log(filterExtractedDate);
          let sequence = 0;
          let categoryIDs = 1;
          sortData.forEach((value, index) => {
            DetailedSheet.getRow(index).height = 25;
            if (categoryIDs == value.CategoryID) {
              sequence++;
            } else {
              sequence = 1;
              categoryIDs = value.CategoryID;
            }
            let showHideInquiry = value.DeletedDate ? "Show Inquiry" : "Hide Inquiry";
            let column = [
              { key: "A", value: value.CategoryID },
              { key: "B", value: value.Category },
              {
                key: "C",
                value: index == 0 ? "Sort No.For Inquiry Item" : sequence,
              },
              { key: "D", value: value.InquiryItem },
              { key: "E", value: value.Department },
              { key: "F", value: value.IchijoIncharge },
              { key: "G", value: value.EmailIchijo },
              { key: "H", value: value.CcIchijoIncharge },
              { key: "I", value: value.CcIchijo },
              {
                key: "J",
                value: index == 0 ? "Show and Hide Category" : showHideInquiry,
              },
              {
                key: "K",
                value: value.DateCreated,
              },
            ];

            let headers = value.ReceivedDate == "Customer Code" ? true : false;
            let borders = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            // let alignment = { wrapText: true };
            let headerFont = { name: "Arial", size: 10, bold: true };
            let Font = { name: "Arial", size: 10, bold: false };

            column.forEach((letter) => {
              // DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment =
              //   letter.key == "M" ? alignment : {};
              DetailedSheet.getCell(`${letter.key}${index + 1}`).border = borders;
              DetailedSheet.getCell(`${letter.key}${index + 1}`).font = headers
                ? headerFont
                : Font;
              DetailedSheet.getCell(`${letter.key}${index + 1}`).value = letter.value;
              if (index + 1 > 1) {
                let heightCell = 0;
                if (letter.key == "S") {
                  let kahitano = letter.value.split("\n");
                  heightCell = kahitano.length * 11;
                  DetailedSheet.getRow(index + 1).height = heightCell;
                }
              }
              if (letter.key !== "S") {
                DetailedSheet.getCell(`${letter.key}${index + 1}`).alignment = {
                  vertical: "middle",
                  horizontal: "center",
                };
              }
            });
          });

          const buffer = workbook.xlsx.writeBuffer();
          resolve(buffer);
        });
      };
      firstProcess().then((val) => {
        const blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Extraction Master(${moment().format("YYYY-MM-DD")})`);
      });
      // const buffer = workbook.csv.writeBuffer();
      //     resolve(buffer);
      //   });
      // };
      // firstProcess().then((val) => {
      //   const blob = new Blob([val], {
      //     type: "text/csv",
      //   });
      //   saveAs(blob, `Extraction Master(${moment().format("YYYY-MM-DD")})`);
      // });
    },

    //**********************************//
    //   GET RECORD FROM FILTER.VUE     //
    //**********************************//

    getFilterRecord(val) {
      for (let x in val) this.$set(this.filter, x, val[x]);
    },
    showInquiry(index) {
      // SHOW CONFIRM DIALOG
      this.$confirm({
        title: "Category",
        message: "Confirm to Show",
        button: {
          no: this.$t("dialog.no"),
          yes: this.$t("dialog.yes"),
        },
        callback: async (confirm) => {
          // IF YES
          if (confirm) {
            // REMOVE RECORD IN TABLE
            for (let x in this.InquiryMasterRecord)
              if (this.InquiryMasterRecord[x].id == index.id)
                // this.InquiryMasterRecord.splice(x, 1);
                this.InquiryMasterRecord[x].DeletedDate = null;
            // HIDE RECORD IN DATABASE
            let data = {
              id: index.id,
              DeletedDate: null,
            };
            this.$axios
              .put(
                `${this.$BaseUrl}/hideShowInquiryMaster`,
                { data: data },
                this.$BaseApiKey
              )
              .then(() => this.$toast.success("Show Successfully", objects.alert));
          }
        },
      });
    },
    hideInquiry(index) {
      // SHOW CONFIRM DIALOG
      this.$confirm({
        title: "Category",
        message: "Confirm to hide",
        button: {
          no: this.$t("dialog.no"),
          yes: this.$t("dialog.yes"),
        },
        callback: async (confirm) => {
          // IF YES
          if (confirm) {
            // REMOVE RECORD IN TABLE
            for (let x in this.InquiryMasterRecord)
              if (this.InquiryMasterRecord[x].id == index.id)
                // this.InquiryMasterRecord.splice(x, 1);
                this.InquiryMasterRecord[x].DeletedDate = moment().format(
                  "YYYY-MM-DD hh:mm:ss"
                );
            // HIDE RECORD IN DATABASE
            let data = {
              id: index.id,
              DeletedDate: moment().format("YYYY-MM-DD hh:mm:ss"),
            };
            this.$axios
              .put(
                `${this.$BaseUrl}/hideShowInquiryMaster`,
                { data: data },
                this.$BaseApiKey
              )
              .then(() => this.$toast.success("Hide Successfully", objects.alert));
          }
        },
      });
    },
    //*******************************//
    //  DELETE FUNCTION              //
    //  *ONCLICK                     //
    //*******************************//

    onDelete(index) {
      // SHOW CONFIRM DIALOG
      this.$confirm({
        title: "Category",
        message: "Confirm to delete",
        button: {
          no: this.$t("dialog.no"),
          yes: this.$t("dialog.yes"),
        },
        callback: async (confirm) => {
          // IF YES
          if (confirm) {
            // REMOVE RECORD IN TABLE
            for (let x in this.InquiryMasterRecord)
              if (this.InquiryMasterRecord[x].id == index.id)
                this.InquiryMasterRecord.splice(x, 1);
            // DELETE RECORD IN DATABASE
            this.$axios
              .delete(
                `${this.$BaseUrl}/deleteInquiryMaster/${index.id}`,
                this.$BaseApiKey
              )
              .then(() => this.$toast.success("Deleted Successfully", objects.alert));
          }
        },
      });
    },

    //**************************//
    //    GET INQUIRY RECORD    //
    //**************************//

    getInquiryMaster() {
      this.$axios
        .get(`${this.$BaseUrl}/getInquiryMaster`, this.$BaseApiKey)
        .then((res) => {
          // CHECK FOR CC INFORMATION
          // CONVERT ARRAY TO TEXT
          res.data.forEach((el) => {
            this.CCHeaders.forEach((Cc) => {
              if (el[Cc]) el[Cc] = JSON.parse(el[Cc]).join(", ");
            });

            el.DateCreated = moment(el.DateCreated).format("YYYY-MM-DD");
          });
          this.InquiryMasterRecord = res.data;
          // this.loading = false;
          // console.log(this.InquiryMasterRecord);
        });
    },
  },
};
</script>

<style lang="scss">
.categoryCard {
  max-height: calc(140vh - 200px);
}
.v-data-table-row .button {
  display: none;
}

.v-data-table-row:hover .button {
  display: block;
}
</style>
