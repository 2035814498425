<template>
  <v-container>
    <v-row>
      <v-col>
        <!-- CATEGORY -->
        <v-autocomplete
          v-model="filter.Category"
          :items="categories"
          @change="GetCategoryItem(filter.Category)"
          color="#df6464"
          label="Category"
          item-text="text"
          item-value="text"
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <!-- INQUIRY ITEM -->
        <v-autocomplete
          v-model="filter.InquiryItem"
          @change="$emit('GetFilter', filter)"
          :items="inquiryItem"
          item-text="InquiryItem"
          item-value="InquiryItem"
          color="#df6464"
          label="Item"
          :disabled="!filter.Category"
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <!-- EMAIL ICHIJO -->
        <v-autocomplete
          v-model="filter.EmailIchijo"
          :items="EmailIchijo"
          @change="$emit('GetFilter', filter)"
          color="#df6464"
          label="Email Ichijo"
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <!-- ICHIJO INCHARGE -->
        <v-autocomplete
          v-model="filter.IchijoIncharge"
          :items="IchijoIncharge"
          @change="$emit('GetFilter', filter)"
          color="#df6464"
          label="HandleBy (Ichijo)"
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <!-- EMAIL GC -->
        <v-autocomplete
          v-model="filter.EmailGc"
          :items="EmailGc"
          @change="$emit('GetFilter', filter)"
          color="#df6464"
          label="Email GC"
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <!-- GC INCHARGE -->
        <v-autocomplete
          v-model="filter.GcIncharge"
          :items="GcIncharge"
          @change="$emit('GetFilter', filter)"
          color="#df6464"
          label="HandleBy (GC)"
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>
      <v-col>
        <!-- DEPARTMENT -->
        <v-autocomplete
          v-model="filter.Department"
          :items="ListDepartment"
          @change="$emit('GetFilter', filter)"
          color="#df6464"
          label="Department"
          dense
          outlined
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import allApi from "../../../functions/httpRequest/hrdappsAPI/allConnection";
import InquiryCategoryTable from "../../../functions/httpRequest/ExternalConnection/InquiryCategory";
import CategoryTable from "../../../functions/httpRequest/ExternalConnection/CategoryInformationTable";

export default {
  data() {
    return {
      filter: {}, //ALL RECORDS TO BE FILTER
      ListDepartment: [], //ALL DEPARTMENT
      categories: [], //ALL CATEGORIES
      inquiryItem: [], // ALL INQUIRY ITEM
    };
  },
  props: {
    // CHECKBOX FROM PARENT
    toggle: {
      type: Boolean,
    },
    // ALL CATEGORY INFORMATION RECORD
    AllRecord: {
      type: Array,
    },
  },

  watch: {
    toggle(newVal) {
      // WATCH IF CHECKBOX IS TRUE
      if (newVal) {
        this.getDepartment();
        this.getCategories();
      }
    },
  },
  computed: {
    //*****************************************//
    //   FILTER AVAILABLE EMAIL AND EMPLOYEE   //
    //*****************************************//

    EmailIchijo() {
      return [...new Set(this.AllRecord.map((data) => data.EmailIchijo))].filter(
        (el) => el
      );
    },
    IchijoIncharge() {
      return [...new Set(this.AllRecord.map((data) => data.IchijoIncharge))].filter(
        (el) => el
      );
    },
    EmailGc() {
      return [...new Set(this.AllRecord.map((data) => data.EmailGc))].filter((el) => el);
    },
    GcIncharge() {
      return [...new Set(this.AllRecord.map((data) => data.GcIncharge))].filter(
        (el) => el
      );
    },
  },
  methods: {
    //**********************************************//
    //  GET ALL DEPARTMENT IN HRD PLAN PRODUCTION   //
    //**********************************************//

    getDepartment() {
      allApi.HRDDepartmentlist().then((res) => {
        let record = res;
        // REMOVE DUPLICATE DEPARTMENT
        this.ListDepartment = [
          ...new Set(record.map((data) => data.DepartmentName)),
        ].sort();
      });
    },

    //*************************************//
    //   GET CATEGORY AND ARRANGE OBJECT   //
    //*************************************//

    getCategories() {
      InquiryCategoryTable.GetSelectCategories().then(
        (res) =>
          (this.categories = res.data.map((el) => {
            return {
              text: el.Japanese,
              value: el.id,
              inquiryID: el.InquiryID,
            };
          }))
      );
    },

    //*************************************//
    //   ONCLICK                           //
    //   GET ALL ITEM BASED ON CATEGORY    //
    //*************************************//
    GetCategoryItem(item) {
      if (typeof item != "undefined") {
        this.$emit("GetFilter", this.filter);

        item = this.categories.filter((el) => el.text == item)[0].value;
        CategoryTable.getCategory(item).then((res) => (this.inquiryItem = res.data));
      } else {
        this.filter.Category = "";
        this.filter.InquiryItem = "";
        this.$emit("GetFilter", this.filter);
      }
    },
  },
};
</script>
