<template>
  <!-- DIALOG FOR INFORMATION -->
  <v-dialog
    v-model="informationDialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-container>
        <v-list-item-title
          class="text-center"
          style="font-weight: bold; font-size: 20px"
          >CC Information
        </v-list-item-title>
        <v-card-subtitle class="text-center" style="color: red; font-size: 15px;"><b>Note:</b>click the name and email to delete</v-card-subtitle>
        <v-divider class="mb-n5 mt-2"></v-divider>
        <v-row>
          <v-col>
            <br>
            
            <v-card-subtitle class="text-center">EmployeeName</v-card-subtitle>
            <!-- ICHIJO INCHARGE -->
            <v-row>
              <v-col v-if="records.CcIchijoIncharge">
                <div
                  class="text-center"
                  v-for="(inchargeIchijo, index) in records.CcIchijoIncharge"
                  :key="index"
                >
                  <v-chip
                    class="mr-1 mb-1"
                    color="#df6464"
                    style="color: white"
                    @click="
                      deleteRecord(
                        index,
                        'CcIchijoIncharge',
                        'CcIchijoIncharge_ID'
                      )
                    "
                    >{{ inchargeIchijo }}</v-chip
                  >
                </div>
              </v-col>
              <v-col class="text-center" v-else>
                <span style="color: red">No Record Found</span>
              </v-col>
            </v-row>
            <v-card-subtitle class="text-center">Email Address</v-card-subtitle>
            <!-- EMAIL ICHIJO -->
            <v-row>
              <v-col v-if="records.CcIchijo">
                <div
                  class="text-center"
                  v-for="(EmailIchijo, index) in records.CcIchijo"
                  :key="index"
                >
                  <v-chip
                    class="mr-1 mb-1"
                    color="#df6464"
                    style="color: white"
                    @click="deleteRecordEmail(index, 'CcIchijo')"
                    >{{ EmailIchijo }}</v-chip
                  >
                </div></v-col
              >
              <v-col class="text-center" v-else>
                <span style="color: red">No Record Found</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      informationDialog: false,
    };
  },

  props: ["records"],
  methods: {
    updateDialog() {
      this.informationDialog = true;
    },
    deleteRecord(index, incharge, id) {
      this.$confirm({
        title: "Delete",
        message: "Confirm to delete",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.$delete(this.records[incharge], index);
            this.$delete(this.records[id], index);
            this.$emit("deleteRecord");
            if (!this.records[incharge].length) {
              delete this.records[incharge];
              delete this.records[id];
            }
            this.informationDialog = false;
            this.informationDialog = true;
          }
        },
      });
    },
    deleteRecordEmail(index, email) {
      this.$confirm({
        title: "Delete",
        message: "Confirm to delete",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.$delete(this.records[email], index);
            this.$emit("deleteRecord");
            if (!this.records[email].length) {
              delete this.records[email];
            }
            this.informationDialog = false;
            this.informationDialog = true;
          }
        },
      });
    },
  },
};
</script>