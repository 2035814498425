<template>
  <v-container>
    <!-- HEADER -->
    <div class="text-center section">
      <h2 class="h2">Template</h2>
      <p class="text-lg font-medium text-gray-600 mb-6" style="color: grey">
        Template for correction request
      </p>
    </div>
    <div style="width: 30%">
      <v-text-field
        v-model="searchTemplate"
        label="Search"
        outlined
        dense
      ></v-text-field>
    </div>
    <v-data-table
      :headers="headers"
      :items="informationRecord"
      :item-per-page="10"
      :search="searchTemplate"
      class="elevation-2"
    >
      <template v-slot:[`item.To`]="{ item }">
        <v-row v-if="!item.To">
          <span style="color: red; font-weight: bold">No Record Found</span>
        </v-row>
        <div v-else>
          <td v-for="(val, index) in item.To" :key="index">
            <v-chip color="#06d6a0" style="color: white">{{ val }}</v-chip>
          </td>
        </div>
      </template>
      <template v-slot:[`item.Cc`]="{ item }">
        <v-row v-if="!item.Cc">
          <span style="color: red; font-weight: bold">No Record Found</span>
        </v-row>
        <div v-else>
          <td v-for="(val, index) in item.Cc" :key="index">
            <v-chip color="#06d6a0" style="color: white">{{ val }}</v-chip>
          </td>
        </div>
      </template>
      <template v-slot:[`item.Action`]="{ item }">
        <td>
          <v-btn
            @click="viewTemplate(item)"
            width="100px;"
            color="#FF1744"
            small
            dark
            >View</v-btn
          >
          <v-btn color="success" @click="ApproveTemplate(item.id)" small
            >Approve</v-btn
          >
        </td>
      </template>
    </v-data-table>

    <!-- DIALOG FOR VIEW TEMPLATE BUTTON -->
    <v-dialog
      flat
      v-model="viewTemplateDialog"
      max-width="800px"
      transition="fade"
    >
      <v-card height="auto" class="pa-10" style="background-color: #fffafa">
        <v-divider class="mb-1" />
        <span><strong>Job Kind:</strong> {{ JobKindTitle }}</span>
        <br />
        <span><strong>Inquiry Kind: </strong>{{ InquiryKindTitle }}</span>
        <br /><br />
        <span><strong>Template:</strong></span>
        <v-textarea
          style="margin-left: -10px"
          readonly
          flat
          solo
          auto-grow
          dense
          v-model="previewTemplate"
        ></v-textarea>
        <br />
        <span><strong>To: </strong>{{ ToTitle }}</span>
        <br />
        <span><strong>Cc: </strong>{{ CcTitle }}</span>
        <v-divider class="mt-2" />
      </v-card>
    </v-dialog>
    <v-overlay v-model="loading">
      <v-progress-circular width="10" size="90" color="#B71C1C" indeterminate
        ><span style="text-shadow: 0 0 10px orange"
          >Loading...</span
        ></v-progress-circular
      >
    </v-overlay>
  </v-container>
</template>
<script>
import TemplateTable from "../../functions/httpRequest/ExternalConnection/TemplateTable";
import moment from "moment";
import objects from "../../functions/alert";

export default {
  data() {
    return {
      CcTitle: "",
      ToTitle: "",
      InquiryKindTitle: "",
      JobKindTitle: "",
      templateIndex: -1,
      viewTemplateDialog: false,
      searchTemplate: "",
      informationRecord: [],
      previewTemplate: "",
      headers: [
        { text: "Job Kind", value: "JobKind", width: "10%" },
        { text: "Inquiry Kind", value: "InquiryKind", width: "10%" },
        { text: "Template", value: "Template", width: "50%" },
        { text: "Department", value: "Department", width: "10%" },
        { text: "To", value: "To", width: "20%" },
        { text: "Cc", value: "Cc", width: "20%" },
        { text: "Action", value: "Action", width: "5%" },
        { text: "Date Created", value: "DateCreated", width: "20%" },
      ],
      loading: false,
    };
  },
  created() {
    this.loading = true;
    // CALL FUNCTION
    this.GetTemplate();
  },
  methods: {
    openviewTemplateDialog() {
      this.viewTemplateDialog = true;
    },
    //****************************//
    // APPROVED TEMPLATE FUNCTION //
    //****************************//

    ApproveTemplate(id) {
      //  STORE ID OF APPROVED TEMPLATE
      const data = {
        id: id,
        Status: "Approved",
      };
      // CONFIRM DIALOG
      this.$confirm({
        title: "Template",
        message: "Confirm to Approve Template",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          // IF YES
          if (confirm) {
            // REMOVE APPROVED RECORD IN PENDING AND UPDATE DATABASE
            TemplateTable.Update(data).then(() => {
              for (let x in this.informationRecord)
                if (this.informationRecord[x].id == id) {
                  this.informationRecord.splice(x, 1);
                  break;
                }
              this.$toast.success("Approved Successfully", objects.alert);
            });
          }
        },
      });
    },
    viewTemplate(item) {
      this.openviewTemplateDialog();
      this.templateIndex = this.informationRecord.indexOf(item);
      // console.log(this.index);
      // Object.assign(this.informationRecord, item);
      // console.log(item.Template.split(' ').join('<\n>'))
      this.JobKindTitle = item.JobKind;
      this.InquiryKindTitle = item.InquiryKind;
      this.previewTemplate = item.Template.split(" ").join("\n");
      this.ToTitle = item.To;
      this.CcTitle = item.Cc;
      // console.log(item.Cc)
      //  this.informationRecord.id= item.id
      //  console.log(this.informationRecord.Template)
    },
    //********************************//
    //  GET PENDING TEMPLATE FUNCTION //
    //********************************//
    GetTemplate() {
      TemplateTable.GetInformation().then((res) => {
        // console.log(res.data)
        res.data.forEach((el) => {
          el.DateCreated = moment().format("YYYY-MM-DD");
          el.Template = (el.Template || "").split("<br>").join(" ");
          if (el.To) el.To = JSON.parse(el.To);
          if (el.Cc) el.Cc = JSON.parse(el.Cc);
        });
        this.informationRecord = res.data.filter(
          (el) => el.Status != "Approved"
        );
        this.loading = false;
      });
    },
  },
};
</script>
