<template>
  <div>
    <v-dialog v-model="dialog" max-width="780px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-card-title primary-title> Conversation Thread </v-card-title>
        <v-divider class="mb-5" />
        <!-- CONVERSATION THREAD -->
        <v-card
          v-for="(val, i) in record"
          :key="i"
          class="pa-2 ma-4"
          elevation="5"
          style="max-height: 800px; min-height: 150px"
        >
          <v-row>
            <v-col cols="1">
              <v-avatar>
                <img :src="GetPicture(val.SenderID)" @error="changeImage" />
              </v-avatar>
            </v-col>
            <v-col cols="9">
              <span class="ml-1" v-text="'From'" style="color: #808080" />:
              {{ val.SenderName }} <br />
              <span class="ml-1" v-text="'Subject'" style="color: #808080" />:
              {{ val.Subject }}
            </v-col>
            <v-col>
              <span
                class="ml-1"
                v-text="GetDate(val.DateCreated)"
                style="color: #808080"
              /><br />
              <!-- <span
                class="ml-1"
                v-text="GetTime(val.DateCreated)"
                style="color: #808080"
              /> -->
            </v-col>
            <v-col>
              <span><b>Attachment:</b></span>
              <br />
              <span v-if="!val.Attachment" style="color: red">No attachment</span>
              <div
                v-else
                v-for="(val1, index) in splitAttachment(val.Attachment)"
                :key="index"
              >
                <v-spacer></v-spacer>
                <span
                  style="color: #1976d2; cursor: pointer; font-size: 13px"
                  v-if="$route.path === '/MailboxManagement'"
                  @click="openAttachment(i, val1.OriginalName)"
                  >{{ val1.ClientName }}</span
                >
                <span
                  v-else
                  @click="downloadPdf(i, val1.OriginalName)"
                  style="color: #1976d2; cursor: pointer; font-size: 13px"
                  >{{ val1.ClientName }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-divider class="mb-2" />
          <div class="pa-2">
            <span v-text="'Message'" style="color: #c0c0c0; font-size: 12px" /><br />
            <span v-html="val.MessageContent" />
          </div>
          <!-- <v-btn
            color="primary"
            :to="{
              name: 'receivedReply',
              params: { id: val.ConfirmationID },
            }"
            >Reply This Message</v-btn
          > -->
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import moment from "moment";
import Others from "../../../functions/alert";
import ReplyTable from "../../../functions/httpRequest/ExternalConnection/ReplyTable";
import awsFunction from "../../../functions/Attachment";
import moment from "moment";
export default {
  data() {
    return {
      dialog: false,
      id: "",
      record: [],
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue) this.GetReplyThread(this.id);
    },
  },
  methods: {
    openAttachment(Index, val) {
      var ResponseContentTypes = "";
      // var Path = item.Path;
      // var Attachment = item.Attachment;
      var Attachment = val;
      var contentType = Attachment.substr(Attachment.lastIndexOf(".") + 1);

      if (contentType === "pdf") {
        ResponseContentTypes = "application/" + contentType;
      } else if (
        contentType === "jpg" ||
        contentType === "jpeg" ||
        contentType === "png"
      ) {
        ResponseContentTypes = "image/" + contentType;
      } else {
        console.log("Other format Attachment");
      }

      // awsFunction.openFileParams(Path, Attachment, ResponseContentTypes);
      awsFunction.openFileParams(this.record[Index].Path, val, ResponseContentTypes);
    },
    splitAttachment(attachment) {
      let splitAttachment = attachment.split(",");

      const splitDate = [];
      for (let x in splitAttachment) {
        splitDate.push({
          OriginalName: splitAttachment[x],
          ClientName: splitAttachment[x].split("_")[1],
        });
      }
      return splitDate;
    },
    async downloadPdf(Index, val) {
      awsFunction.download(this.record[Index].Path, val);
      //   const pathViewer = await awsFunction.convertToFileType([`${Path}/${Attachment}`]);
      //   // console.log(pathViewer)
      //  window.URL.createObjectURL(pathViewer)
    },
    //********************//
    //  EMPLOYEE PICTURE  //
    //********************//

    GetPicture(code) {
      return Others.EmployeePicture.Picture(code);
    },

    //**********************//
    //   NO AVAILABLE SRC   //
    //**********************//

    changeImage(event) {
      event.target.src = require("../../../assets/no-image.png");
    },

    //******************************//
    //     GET ALL REPLY THREAD     //
    //******************************//

    GetReplyThread(id) {
      ReplyTable.GetReply(id).then((res) => {
        this.record = res.data;
        this.record.sort((a, b) => (a.id > b.id ? -1 : 1));
      });
    },

    //**********************************//
    //    VALIDATE DATE TIME FORMAT     //
    //**********************************//

    GetDate: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
    // GetTime: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),

    //**********************//
    //     OPEN DIALOG      //
    //**********************//

    OpenDialog(id) {
      this.id = id;
      this.dialog = !this.dialog;
    },
  },
};
</script>
