<template>
  <div>
    <!-- BUTTON TRIGGER -->
    <v-btn
      color="#f7347a"
      @click="showCategoryModal = !showCategoryModal"
      dense
      small
      outlined
      >Add/Edit Category</v-btn
    >

    <!-- FOR CATEGORY -->
    <v-dialog
      v-model="showCategoryModal"
      :overlay="false"
      max-width="450"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <span
          class="text-lg font-medium text-gray-600"
          style="color: black; font-weight: bold"
        >
          Add / Edit Category
        </span>

        <!-- BUTTONS FOR ADD OR EDIT -->
        <v-row v-show="!checkCategory">
          <v-col>
            <v-btn @click="checkCategory = 'Add'" color="#f7347a" outlined block
              >Add</v-btn
            >
          </v-col>
          <v-col>
            <v-btn @click="checkCategory = 'Edit'" color="#f7347a" outlined block
              >Edit</v-btn
            >
          </v-col>
        </v-row>
        <!-- FOR ADD -->
        <v-row v-show="checkCategory == 'Add'">
          <v-col>
            <v-text-field
              v-model="additionalCategory"
              class="mb-n8"
              label="Input Category"
              solo
              dense
            ></v-text-field
            ><br />
            <v-btn @click="addingCategory" color="success" outlined block>Submit</v-btn>
          </v-col>
        </v-row>
        <!-- FOR EDIT -->
        <v-row v-show="checkCategory == 'Edit'">
          <v-col>
            <v-data-table
              :headers="editHeader"
              :items="categories"
              item-key="id"
              :disable-pagination="true"
              :hide-default-footer="true"
              :show-select="false"
            >
              <template v-slot:body="props">
                <draggable @change="updateDragChange" :list="props.items" tag="tbody">
                  <tr
                    @mouseover="hoverEdit = cat.value"
                    v-for="(cat, index) in props.items"
                    :key="index"
                  >
                    <td>
                      <v-icon small style="cursor: move">mdi-arrow-all</v-icon>
                    </td>
                    <td>{{ index + 1 }}</td>
                    <td>{{ cat.text }}</td>

                    <td>
                      <v-btn
                        v-if="hoverEdit == cat.value"
                        @click="getDelete(cat)"
                        color="error"
                        x-small
                        dense
                        outlined
                        block
                        >Delete</v-btn
                      >
                      <v-btn
                        class="mt-2"
                        v-if="hoverEdit == cat.value"
                        @click="getEdit(cat)"
                        color="success"
                        x-small
                        dense
                        block
                        outlined
                        >Edit</v-btn
                      >
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG FOR ADDITIONAL EDIT -->
    <v-dialog v-model="additionalEdit" width="380">
      <v-card class="pa-3">
        <v-text-field v-model="newCategory.text" class="mb-n3" solo dense></v-text-field>
        <v-btn @click="submitEdit(newCategory.val)" color="success" small block
          >Submit</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import objects from "../../../functions/alert";
import InquiryCategory from "../../../functions/httpRequest/ExternalConnection/InquiryCategory";
import moment from "moment";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      showCategoryModal: false,
      additionalEdit: false,
      checkCategory: "",
      additionalCategory: "",
      hoverEdit: "",
      newCategory: {},
      categories: [],
      editHeader: [
        { text: "", sortable: false },
        { text: "#", sortable: false },
        { text: "Category", value: "text", sortable: false },
        { text: "Action", sortable: false },
      ],
    };
  },

  components: {
    // FOR DRAGGABLE TABLE
    draggable,
  },
  watch: {
    // WATCH CATEGORY DATA
    showCategoryModal(newVal) {
      if (!this.showCategoryModal) this.checkCategory = "";
      if (newVal) this.getCategories();
    },
  },
  methods: {
    getDelete(val) {
      val.deletedDate = moment().format("YYYY-MM-DD hh:mm:ss");

      this.$confirm({
        title: "Category",
        message: "Confirm to delete",
        button: {
          no: this.$t("dialog.no"),
          yes: this.$t("dialog.yes"),
        },
        // IF YES
        callback: async (confirm) => {
          if (confirm) {
            for (let x in this.categories)
              if (this.categories[x].value == val.value) {
                this.categories.splice(x, 1);
                InquiryCategory.SoftDelete(val).then(() => {
                  this.$toast.success("Deleted Successfully", objects.alert);
                });
              }
          }
        },
      });
    },
    //*********************************//
    // FOR EDIT FUNCTION IN CATEGORY   //
    //*********************************//

    submitEdit(val) {
      // GET CATEGORY VALUE BASED ON CATEGORY ID
      for (let x in this.categories)
        if (this.categories[x].value == val)
          this.categories[x].text = this.newCategory.text;

      // STORE DATA TO BE EDIT
      const data = {
        Japanese: this.newCategory.text,
        id: val,
      };
      // EDIT DATA IN DATABASE
      InquiryCategory.Update(data).then(() => {
        this.$toast.success("Edit Successfully", objects.alert);
        this.additionalEdit = false;
      });
    },
    //*******************************************//
    //   GET TEXT AND VALUE FROM BUTTON ONCLICK  //
    //*******************************************//

    getEdit(val) {
      this.additionalEdit = true;
      this.newCategory.text = val.text;
      this.newCategory.val = val.value;
    },
    //*********************************//
    //  ADDING NEW CATEGORY FUNCTION   //
    //*********************************//

    async addingCategory() {
      // GET MAX LENGTH FOR GENERATING CATEGORY ID
      const id = this.categories[this.categories.length - 1].value;

      const data = {
        InquiryID: parseInt(id) + 1,
        Japanese: this.additionalCategory,
        English: this.additionalCategory,
      };

      // ADD TO CATEGORY
      this.categories.push({
        text: this.additionalCategory,
        value: parseInt(id) + 1,
      });
      await InquiryCategory.GetByCategories(this.additionalCategory).then(async (res) => {
        if (!res.data.length) {
          await InquiryCategory.insert(data).then(() => {
            this.additionalCategory = "";
            this.$toast.success("Added Succesfully", objects.alert);
            this.showCategoryModal = false;
          });
        } else {
          Swal.fire({
            title: "Category Already Added!",
            icon: "info",
          });
          this.showCategoryModal = false;
        }
      });
      // INSERT DATA IN DATABASE
    },
    //*********************************//
    //     UPDATE DRAG CATEGORY        //
    //*********************************//

    async updateDragChange(attribute) {
      // Get dragged object
      const obj = attribute.moved;

      // Get the current order of categories
      const currentOrder = this.categories.map((el) => el.value);

      // Update the order based on the drag event
      currentOrder.splice(obj.oldIndex, 1); // Remove from old position
      currentOrder.splice(obj.newIndex, 0, obj.element.value); // Insert at new position

      // Update the database with the new order
      for (let i = 0; i < currentOrder.length; i++) {
        const updateData = {
          InquiryID: i + 1,
          id: currentOrder[i],
        };

        await InquiryCategory.UpdateSwap(updateData);
      }

      // Refresh the categories after updating the order
      await this.getCategories();
    },
    //***********************************//
    //  GET AND ARRANGE OBJECT CATEGORY  //
    //***********************************//

    async getCategories() {
      await InquiryCategory.GetSelectCategories().then((res) => {
        console.log(res.data);
        this.categories = res.data
          .map((el) => {
            return {
              text: el.Japanese,
              value: el.id,
              inquiryID: el.InquiryID,
            };
          })
          .sort((a, b) => (a.inquiryID < b.inquiryID ? -1 : 1));
      });
    },
  },
};
</script>
